import { API } from 'aws-amplify';
import * as audit from '@/libs/AuditTrailOperations';
import * as utils from '@/libs/utils';
import CONST from '@/const';
import TRANS_MAP from '@/i18n/TransMap';


// RESOURCE CENTER API AND DOCS HANDLING //
export const processResourceCenterDocuments = async (custoForCountryRC, batch) => {
    try {
        if (!batch)
            // No batch, RC call would throw an error, PDF is missing by definition
            return custoForCountryRC.map(document => handleResourceCenterDocument({ count: 0, custo: document }));

        const documentsRC = await API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.apiRoutes.getRCDocuments, {
            body: {
                custos: custoForCountryRC,
                batch: batch
            }
        });
        return documentsRC.map(document => handleResourceCenterDocument(document));
    } catch (error) {
        console.error(error);
        return [];
    }
}

const handleResourceCenterDocument = (doc) => {
    let parsedDoc = {};
    switch (doc.count) {
        case 0:
            parsedDoc.documentType = doc.custo.documentType;
            parsedDoc.languages = doc.custo.language;
            parsedDoc.material = doc.custo.material;
            parsedDoc.status = CONST.status.missingPDF;
            break;
        case 1:
            const item = doc.items[0];
            parsedDoc.documentType = doc.custo.documentType;
            parsedDoc.languages = item.file_languages[0];
            parsedDoc.partNumber = item.document_partNumber;
            parsedDoc.lotNumber = item.lot_number;
            parsedDoc.temporaryUrl = item.url;
            parsedDoc.material = item.product_number || doc.custo.material;
            parsedDoc.status = CONST.status.open;
            break;
        default:
            parsedDoc.material = doc.custo.material;
            parsedDoc.documentType = CONST.docTypes.UNKNOWN.code;
            parsedDoc.status = CONST.status.multiplePDF;
    }
    return parsedDoc;
}


// GLT API AND DOCS HANDLING //
// Checks that the GLT Documents exists, set status according to
export const processGLTDocuments = async (custoForCountryGLT) => {
    for (let custo of custoForCountryGLT) {
        const docExists = await gltDocExists(custo.labelPart);
        custo.status = docExists ? CONST.status.open : CONST.status.documentMissing;
    }
    return custoForCountryGLT;
}

// Get the PDF from GLT in base64
export const getGLTDocumentPDF = async (material, labelPart, batch, country, extras_inbound, extras_outbound) => {
    try {
        return await API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.apiRoutes.getGltDocument, {
            body: {
                material,
                labelPart,
                batch,
                country,
                extras_inbound,
                extras_outbound
            }
        });
    } catch (error) {
        console.error(error);
        return "";
    }
}

// Check if documents exists in GLT from the label part
const gltDocExists = async (labelPart) => {
    try {
        const response = await API.get(process.env.REACT_APP_AMPLIFY_API_NAME,
            CONST.apiRoutes.gltDocExists,
            {
                queryStringParameters: {
                    labelPart,
                }
            });
        return response.exists;
    } catch (error) {
        console.error(error);
        return false;
    }
}


// CUSTO FOR COUNTRY API AND DOCS HANDLING //
// Get all existing customizations available from form inputs
// Response contains an array for doc types 01 and 02 (RC docs) and an array for doc types 03 and 04 (GLT docs)
export const getCustoForCountry = async ({ matnr, ean, country, customer, delivery, outSerialNb }) => {
    try {
        return await API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.apiRoutes.countryCusto, {
            body: {
                matnr,
                ean,
                country,
                customer,
                delivery,
                outSerialNb
            }
        });
    } catch (error) {
        if (error.response && error.response.status === 404)
            return { status: error.response.status }
        throw error
    }
}

export const getInboundCusto = async ({ matnr, receivingPlant }) => {
    try {
        return await API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.apiRoutes.inboundCusto, {
            body: {
                matnr,
                receivingPlant,
            }
        });
    } catch (error) {
        if (error.response && error.response.status === 404)
            return { status: error.response.status }
        throw error
    }
}


// Check that all parsed datas have existing document to print
export const checkDocumentsDatas = (RCDatas, GLTDatas) => {
    let documentsCheck = false;
    let statusCheck = TRANS_MAP.messageTechnicalError;
    let hasRCcustos = RCDatas.length !== 0;
    let hasRCdocs = RCDatas.filter(data => data.status === CONST.status.open).length !== 0;
    let hasGLTcustos = GLTDatas.length !== 0;
    let hasGLTdocs = GLTDatas.filter(data => data.status === CONST.status.open).length !== 0;


    if ((hasGLTcustos && hasGLTdocs) || (hasRCcustos && hasRCdocs)) {
        documentsCheck = true;
    } else if (hasGLTcustos && !hasGLTdocs && !hasRCcustos && !hasRCdocs) {
        statusCheck = TRANS_MAP["document-missing"];
    } else if (!hasGLTdocs && hasRCcustos && !hasRCdocs) {
        statusCheck = TRANS_MAP.messagePDFMissing;
    } else if (!hasGLTdocs && !hasRCcustos && !hasRCdocs) {
        statusCheck = TRANS_MAP['no-document'];
    }
    return { documentsCheck, statusCheck };
}


// Update already existing printings
export const updateDocuments = async ({ operation, existingPrintings, batch }) => {
    for (let print of existingPrintings) {
        if (utils.isGLTDoc(print.document_type)) {
            await updateGLTDocument(print);
        } else if (utils.isRCDoc(print.document_type)) {
            await updateRCDocument({ operation, print, batch })
        }
    }
}


const updateGLTDocument = async (print) => {
    const docExists = await gltDocExists(print.label_part);
    const status_message = docExists ? CONST.status.open : CONST.status.noDocument;
    print.status_message = status_message;
    await audit.updatePrinting({ printing_id: print.id, status: status_message })
}

const updateRCDocument = async ({ operation, print, batch }) => {
    // Recreate payload for getrcdocuments lambda
    const custos = [{
        documentType: print.document_type,
        language: print.languages,
        material: operation.item,
    }];

    try {
        const document = await API.post(process.env.REACT_APP_AMPLIFY_API_NAME, CONST.apiRoutes.getRCDocuments, {
            body: {
                custos,
                batch: operation.batch && operation.batch !== "" ? operation.batch : batch
            }
        });

        if (document.length !== 1)
            return;

        const newDatas = handleResourceCenterDocument(document[0]);

        print.status_message = newDatas.status;
        print.document_type = newDatas.documentType;
        print.languages = newDatas.languages;
        print.part_number = newDatas.partNumber;
        print.temporary_url = newDatas.temporaryUrl;

        await audit.updatePrinting({
            printing_id: print.id,
            document_type: print.document_type,
            languages: print.languages,
            part_number: print.part_number,
            temporary_url: print.temporary_url || "",
            status: print.status_message,
        });

    } catch (error) /* istanbul ignore next */ {
        console.error(error);
    }
}
